<template>
  <div class="d-flex align-center mb-2 px-3">
    <v-avatar
      :class="{'square': $attrs.square}"
      class="mr-3"
      slot="icon"
      size="25"
      color="primary"
    >
      <v-icon small color="accent">mdi-{{ $attrs.icon }}</v-icon>
    </v-avatar>
    <p>
      <small>
        <slot></slot>
      </small>
    </p>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,

  components: {
  },

  props: {
  },

  data() {
    return {
    };
  },

  computed: {
  },

  created() {
  }
};
</script>

<style scoped>
p {
  line-height: 1.1em;
}
.square {
  border-radius: 2px;
}
</style>
