<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">画像バナーの管理</h1>
        <banner-hint>
          登録した画像バナーはホーム画面管理にてバナーパーツを使ってホーム画面内の好きなところに表示できます。<br />
          画像バナーは固定コンテンツとなるので常に表示しておきたい情報を表示するのに最適です。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- 新規追加ボタン -->
        <div class="mb-7 d-flex align-end">
          <v-btn
            depressed
            color="primary"
            @click="addBanner()"
          >新着バナーを追加
          </v-btn>
          <icon-info icon="lightbulb-on-outline" class="ml-2" :square="true">
            バナー画像は画面横幅一杯に伸長して表示されるので縦幅の小さい横長の画像を使用してください。
          </icon-info>
        </div>

        <v-card v-if="!banners.length" flat>
          <v-card-text>バナー画像が登録されていません。</v-card-text>
        </v-card>

        <v-card
          class="my-5 pt-8 pb-7 px-6"
          elevation="1"
          v-for="(banner, index) in banners"
          :key="banner.banner_id"
        >
          <v-form :ref="'form-banner-' + banner.banner_id">
            <!-- バーナー名 -->
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="banner.banner_name"
                  outlined
                  required
                  label="バナータイトル"
                  counter="20"
                  :rules="[valiRules.required, valiRules.max20]"
                ></v-text-field>
              </v-col>
              <!-- バナー画像 -->
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="8">
                    <v-file-input
                      v-model="updateImages[banner.banner_id]"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      required
                      prepend-icon="mdi-image"
                      label="バナー画像"
                      hint="画像ファイルのサイズ上限は1MBです"
                      persistent-hint
                      :rules="banner.create ? [valiRules.required, valiRules.image] : [valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <!-- サムネイル -->
                  <v-col cols="4" class="mt-n4">
                    <small>設定中の画像</small>
                    <v-img
                      max-height="600"
                      contain
                      :src="banner.image_url"
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- リンクURL -->
            <v-row class="mt-3">
              <v-col cols="12">
                <v-text-field
                  v-model.trim="banner.link_url"
                  outlined
                  required
                  label="リンクURL"
                  counter="150"
                  :rules="[valiRules.max150]"

                ></v-text-field>
              </v-col>
            </v-row>

            <!-- ボタン -->
            <v-row no-gutters class="justify-end">
              <div>
                <v-btn
                  v-if="banner.create"
                  depressed small
                  color="accent"
                  @click="createRow(banner)"
                >登録</v-btn>
                <v-btn
                  v-else
                  depressed small
                  color="primary"
                  @click="updateRow(banner)"
                >更新</v-btn>
                <v-btn
                  class="ml-2"
                  text
                  depressed small
                  color="primary"
                  @click="deleteRow(index)"
                >削除</v-btn>
              </div>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue"
import IconInfo from "@/components/_IconInfo.vue"

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxBannerId: 0,
      banners: [],
      updateImages: [],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.getSiteBanner(),
      ])

      this.loading = false
    },

    //もっと読み込む
    loadMore() {
      this.getSiteBanner()
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //新規追加
    addBanner() {
      if (this.banners.length >= 10) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.banners.unshift({
        banner_id: ++this.maxBannerId,
        banner_name: '',
        text: '',
        image_url: '',
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //
    // API req: GET
    //
    getSiteBanner() {
      return this.adminApi.getReqWithAuth('site-banner/').then( records => {
        if (!records || !records.length) return

        //id最大値の取得
        this.maxBannerId = Math.max(...records.map( row => parseInt(row.banner_id) ))

        records.map( row => { this.banners.push(row) })
      })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(banner) {
      if (!this.$refs['form-banner-' + banner.banner_id][0].validate() ||
        !banner.banner_name || !this.updateImages[banner.banner_id]
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append('banner_name', banner.banner_name)
      formData.append('image_url', this.updateImages[banner.banner_id])
      formData.append('link_url', banner.link_url || '')

      this.adminApi.apiReqWithData('POST', 'create/site-banner/', formData).then( response => {
        banner.banner_id = response.banner_id
        banner.image_url = response.image_url
        delete this.updateImages[banner.banner_id]
        banner.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(banner) {
      if (!this.$refs['form-banner-' + banner.banner_id][0].validate() ||
        !banner.banner_name || (!banner.image_url && !this.updateImages[banner.banner_id])
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }
      const apiPath = 'update/site-banner/' + banner.banner_id
      const formData = new FormData()
      formData.append('banner_name', banner.banner_name)
      formData.append('image_url', this.updateImages[banner.banner_id] || banner.image_url)
      formData.append('link_url', banner.link_url || '')

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
          return
        }

        delete this.updateImages[banner.banner_id]
        banner.image_url = response.image_url

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.banners[index].create) {
        this.banners.splice(index, 1)
        return
      }

      const apiPath = 'delete/site-banner/' + this.banners[index].banner_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.banners.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    }
  }
}
</script>

<style scoped>
</style>
